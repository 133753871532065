import React, { useEffect, useState } from 'react'
import { Col, Row } from "react-bootstrap"
import { Circles } from 'react-loader-spinner'

//redux
import { useSelector } from "react-redux"

import Plyr from 'plyr-react';
import 'plyr-react/plyr.css'

import * as setup from "models/setup";
import * as upload from "models/uploads";
import toast from 'react-hot-toast';
import LandingPage from 'components/LandingPage';
import UnitsTopicsLayout from 'components/UnitTopic';
import FileCard from "components/FileCard"
import FileViewer from "components/FileViewer";

import Dashboard from 'pages/Dashboard';
import { set } from 'lodash';


const Home = (prop) => {
  const levelTypes = useSelector(state => state.LevelType.data?.results || []);
  const userProfile = useSelector(state => state.LevelType.data?.results || []);

  const [listedSubjects, setListedSubjects] = useState([])
  const [listedSubTopics, setListedSubTopics] = useState([])
  const [listedContent, setListedContent] = useState([])

  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedSubTopic, setSelectedSubTopic] = useState(null)
  const [selectedSubjectTab, setSelectedSubjectTab] = useState("")

  const [isLoading, setIsLoading] = useState(false)

  const [downloadReady, setDownloadReady] = useState(true)
  const [signTrigger, setSignTrigger] = useState(true)
  const [selected, setSelected] = useState(null)
  const [showFileViewer, setShowFileViewer] = useState(false)

  const [activeSubjectTab, setActiveSubjectTab] = useState(null)
  const [activeFileTab, setActiveFileTab] = useState({
    name: "All", value: "all"
  })
  const [activeContentType, setActiveContentType] = useState("Learning Materials")
  
  const contentType = [
  "Learning Materials",
    "Quizes",
   ]

  const fileTabs = [
    { name: "All", value: "all", icon: "fas fa-file" },
    { name: "Pdf", value: "pdf", icon: "fas fa-file" },
    { name: "PowerPoint", value: "pptx", icon: "fas fa-file" },
    { name: " Audios", value: "audios", icon: "fas fa-volume-up" },
    { name: " Videos", value: "videos", icon: "fas fa-video" },
    { name: "Lectures", value: "lectures", icon: "fas fa-stream" },
    { name: "Practicals ", value: "practicals", icon: "fas fa-video" },
    { name: "Discussions ", value: "discussion", icon: "fas fa-users" },
  ]

  //groped topics by unit
  function groupTopicsByUnit(topics) {
    const grouped = topics.reduce((acc, topic) => {
      // Find or create the unit entry in the accumulator array
      let unitEntry = acc.find(entry => entry.unit === topic.unit);

      // If the unit entry doesn't exist, create it
      if (!unitEntry) {
        unitEntry = { unit: topic.unit, unitId: topic.unitId, topics: [] };
        acc.push(unitEntry);
      }

      // Add the topic to the topics array of the found or created unit entry
      unitEntry.topics.push(topic);

      return acc;
    }, []);

    return grouped;
  }

    //groped sub-topics by topic
    function groupSubTopicsByTopic(subTopics){
      const grouped = subTopics.reduce((acc, subTopic) => {
        // Find or create the unit entry in the accumulator array
        let topicEntry = acc.find(entry => entry.topic === subTopic.topic);
  
        // If the unit entry doesn't exist, create it
        if (!topicEntry) {
          topicEntry = { topic: subTopic.topic, topicId: subTopic.topic, subTopics:[] };
          acc.push(topicEntry);
        }
  
        // Add the topic to the topics array of the found or created unit entry
        topicEntry.subTopics.push(subTopic);
  
        return acc;
      }, []);
  
      return grouped;
    }

  const getAllTopics = (params) => {
    setIsLoading(true)
    setActiveSubjectTab(null)
    setSelectedSubTopic(null)
    setSelectedTopic(null)
    setup.getAllTopics(params)
      .then(resp => {
        const data = resp.results
        setListedSubjects(groupTopicsByUnit(data))
        setIsLoading(false)
      }).catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const getAllSubTopics = (params) => {
    setIsLoading(true)
    setup.getAllSubTopics(params)
      .then(resp => {
        const data = resp?.results
        setListedSubTopics(groupSubTopicsByTopic(data))
        setIsLoading(false)
      }).catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const getAllContents = (params) => {
    setIsLoading(true)
    upload.getUploads(params)
      .then(resp => {
        const data = resp
        setIsLoading(false)
        setListedContent(data)

      }).catch(err => {
    toast.error(err.response.data.message)
      })
  }

  console.log(localStorage.getItem("Token"))

  useEffect(() => {
const userProfile = JSON.parse(localStorage.getItem("teutiUser"))
    if (userProfile) {
      setSignTrigger(false)
    }
  }, [prop.updateLocalStorage])
  useEffect(() => {
    if(prop.selectedLevel){
      getAllTopics(`level=${prop.selectedLevel}`)
    }
  }, [prop.selectedLevel])

  useEffect(() => {
    if(activeSubjectTab){
      getAllSubTopics( `unit=${activeSubjectTab?.unit}`)
    }
  }, [activeSubjectTab])

  useEffect(() => {
if(selectedSubTopic){
  getAllContents(selectedSubTopic?._id)
}
}, [selectedSubTopic])

  return (

    <div className='page-content bg-white'
    style={{ minHeight: "90vh",position: "relative" }}>

      <div className="text-primary">
        {prop.selectedLevel && (<a onClick={()=>{setActiveSubjectTab(null),setSelectedSubTopic(null)}}>{prop.selectedLevel} </a>)}
        {activeSubjectTab && (<a onClick={()=>setSelectedSubTopic(null)}>| {activeSubjectTab?.unit} </a>)}
        {selectedSubjectTab && (<a>{selectedSubjectTab} </a>)}
        {selectedTopic && (<a>| {selectedTopic} </a>)}
        {selectedSubTopic && (<a>| {selectedSubTopic?.subTopicName} </a>)}
      </div>

{activeSubjectTab&&(
<div>
{contentType.map((item, index) => (
  <button key={index}
  className={`btn btn-sm btn-${activeContentType===item?"primary":"outline-primary"} me-2`}
  onClick={()=>{setActiveContentType(item)}}>
{item}
  </button>
))}
</div>
)}

{/* <CardProfile/> */}
    {prop.selectedLevel?(
      <div>
      {isLoading?(
        <div className="d-flex justify-content-center align-items-center"
        style={{ height: '75vh' }}>
               <Circles
    height="80"
    width="80"
     color="#2D3194"
     ariaLabel="circles-loading"
    wrapperStyle={{}}
     wrapperClass=""
    visible={true}
     />

        </div>):(
          <>
          {!selectedSubTopic&&(
        <UnitsTopicsLayout 
        units={listedSubjects || []}
        topics={listedSubTopics}
        selectedUnit={activeSubjectTab}
        setSelectedUnit={setActiveSubjectTab}
        setSelectedTopic={setSelectedTopic}
        selectedSubTopic={selectedSubTopic}
        setSelectedSubTopic={setSelectedSubTopic}
        activeContentType={activeContentType}
        setActiveContentType={setActiveContentType}
        />)}

      {selectedSubTopic&&(
        <Col className=' position-relative'>
                    {listedContent.length === 0 ? <div className="d-flex text-primary justify-content-center   my-5">
                      <h5>The are no contents for this section</h5>
                    </div> :
                      <div className=" d-flex flex-row">
                        <div className={`d-${showFileViewer ? "none" : "flex"} d-md-flex flex-wrap col col-12 col-md-${showFileViewer ? 4 : 12} col - lg - ${showFileViewer ? 2 : 12} pb - 5 b`}>
                          {listedContent.map((item, index) => (
                            <FileCard
                              key={index}
                              item={item}
                              downloadReady={downloadReady}
                              signTrigger={signTrigger
                              }
                              setSignTrigger={setSignTrigger}
                              setShowSignUpModal={prop.setShowSignUpModal}
                              setFileViewer={setShowFileViewer}
                              fileViewer={showFileViewer}
                              setSelected={setSelected}
                            />
                          ))}
                        </div >

                        <div className=" w-100">
                        {showFileViewer && (
  <FileViewer
    selected={selected}
    setSelelected={setSelected}
    setFileViewer={setShowFileViewer}
    fileViewer={showFileViewer}
    style={{
      position: "absolute",        // Keeps the div fixed on screen
      top: "10%",               // Adjust the distance from the top of the screen
      right: "0",
      width: "50%",             // Set the width of the div
      maxHeight: "80vh",        // Max height based on viewport
      overflowY: "auto",        // Enables scrolling if content exceeds max height
      backgroundColor: "#fff",  // Optional: background color for readability
      zIndex: 9999              // Ensures it stays above other content
    }} // Apply the fixed position class here
  />
)}
                        </div>

                      </div>}
                  </Col>
                )}
        </>)}
    </div>):(
    <LandingPage
      levelTypes={levelTypes}
      setSelectedLevel={prop.setSelectedLevel}
      setShowSignUpModal={prop.setShowSignUpModal}
       />
       )}

    {selectedSubTopic&&(
       <Row
        className="bg-light mb-1"
        style={{
          position: "absolute",
          bottom: "0",
          width: "90%",
        }}>
        <Col
          md={8}
          lg={6}
          className="d-flex scrollable-div justify-content-between p-1 "
          style={{ overflowX: "auto", }}>
          {fileTabs.map(((item, index) => (
            <div
              key={index}
              className={`d-flex align-items-center justify-content-center border-3 btn border-0 border-bottom btn-sm me-2 me-md-3 font-size-10 ps-1 pe-1

            bg-${activeFileTab?.name === item?.name ? "primary" : "white"}
            text-${activeFileTab?.name === item.name ? "white" : "primary"}
            `}
              href={`#${item.name} `}
              onClick={() => setActiveFileTab({ name: item.name, value: item?.value })}
            >
              <i className={`${item?.icon} py-0 my-0 me-1 font-size-`}></i>
              {/* <p className={`mx - 1 my - 0 py - 0 `} > */}
              {item.name}
              {/* </p> */}
            </div>


          )))}
        </Col>


      </Row>
    )}
    </div>
  )
}

export default Home