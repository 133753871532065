import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

import * as test from "models/tests";

const ViewTest = props => {
  const [answers, setAnswers] = useState({});
  const [questions, setQuestions] = useState([])

  const [isLoaded, setIsLoaded] = useState(false)

  const {
    match: { params },
  } = props

  const getQuiz = () => {
    const token= JSON.parse(localStorage.getItem('Token'))
    const authToken=token?.authToken?.token

    setIsLoaded(false)
    test.getQuizById(authToken,params?.id)
      .then(resp => {
        const data = resp
        setQuestions(data?.questions)
        setIsLoaded(true)
      }).catch(err => {

      })
  }

  const getTest = () => {
    const token= JSON.parse(localStorage.getItem('Token'))
    const authToken=token?.authToken?.token

    setIsLoaded(false)
    test.getTestById(authToken,params?.id)
      .then(resp => {
        const data = resp
        setQuestions(data?.questions)
        setIsLoaded(true)
      }).catch(err => {

      })
  }
  
  const handleCheckbox = (e, item) => {
    setCheckedItems({
      ...checkedItems,
      [item.id]: e.target.checked,
    }),
      console.log("checked", e.target.name)

    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter(
          selectedItem => selectedItem?.value !== item?.value
        )
      )
    } else {
      setSelectedItems([...selectedItems, item])
    }
  }
  const handleChange = (questionId, value, isCheckbox = false) => {
    if (isCheckbox) {
      const currentAnswers = answers[questionId] || [];
      const updatedAnswers = currentAnswers.includes(value)
        ? currentAnswers.filter((answer) => answer !== value)
        : [...currentAnswers, value];
      setAnswers({ ...answers, [questionId]: updatedAnswers });
    } else {
      setAnswers({ ...answers, [questionId]: value });
    }
  };

  const renderQuestion = (question) => {
    switch (question.type) {
      case 'mcq':
        return (
          <div className="question">
            <p>{question.question}</p>
            {question.options.map((option, index) => (
              <label key={index} className="option">
                <input
                  type="radio"
                  name={`mcq-${question.id}`}
                  value={option}
                  checked={answers[question.id] === option}
                  onChange={() => handleChange(question.id, option)}
                />
                {option}
              </label>
            ))}
          </div>
        );
      case 'truefalse':
        return (
          <div className="question">
            <p>{question.question}</p>
            <label className="option">
              <input
                type="radio"
                name={`truefalse-${question.id}`}
                value="true"
                checked={answers[question.id] === 'true'}
                onChange={() => handleChange(question.id, 'true')}
              />
              True
            </label>
            <label className="option">
              <input
                type="radio"
                name={`truefalse-${question.id}`}
                value="false"
                checked={answers[question.id] === 'false'}
                onChange={() => handleChange(question.id, 'false')}
              />
              False
            </label>
          </div>
        );
      case 'shortanswer':
        return (
          <div className="question">
            <p>{question.question}</p>
            <input
              type="text"
              value={answers[question.id] || ''}
              onChange={(e) => handleChange(question.id, e.target.value)}
              className="text-input"
            />
          </div>
        );
      case 'essay':
        return (
          <div className="question">
            <p>{question.question}</p>
            <textarea
              value={answers[question.id] || ''}
              onChange={(e) => handleChange(question.id, e.target.value)}
              className="textarea"
            />
          </div>
        );
      case 'fillintheblank':
        return (
          <div className="question">
            <p>{question.question}</p>
            <input
              type="text"
              value={answers[question.id] || ''}
              onChange={(e) => handleChange(question.id, e.target.value)}
              className="text-input"
            />
          </div>
        );
      case 'matching':
        return (
          <div className="question">
            <p>{question.question}</p>
            {question.options.map((option, index) => (
              <div key={index}>
                <span className="match-term">{option.term}</span>
                <input
                  type="text"
                  placeholder="Answer"
                  onChange={(e) => handleChange(`${question.id}-${index}`, e.target.value)}
                  className="text-input"
                />
              </div>
            ))}
          </div>
        );
      case 'checkbox':
        return (
          <div className="question">
            <p>{question.question}</p>
            {question.options.map((option, index) => (
              <label key={index} className="option">
                <input
                  type="checkbox"
                  value={option}
                  checked={(answers[question.id] || []).includes(option)}
                  onChange={() => handleChange(question.id, option, true)}
                />
                {option}
              </label>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (params?.type === "quiz") {
      getQuiz()
    } else if (params?.type === "test") {
      getTest()
    } else { null }
  }, [params?.type])
  return (
    <div className="page-content">

      <div>
        {isLoaded ? (<div className="exam-container">
          <h2>Quiz </h2>
          {questions.map((question) => (
            <div key={question.id} className="question-container">
              {renderQuestion(question)}
            </div>
          ))}
          <button className="submit-btn" onClick={() => console.log(answers)}>
            Submit
          </button>
        </div>) : (
          <div>

          </div>
        )}
      </div>


    </div>
  );
};
ViewTest.propTypes = {}
export default ViewTest;
